import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
  Ratio,
} from "../../commons/Theme";

export const StyledImageWithCards = styled.div`
  background-color: ${Colors.grayLight};
  padding-top: ${Rem(40)};

  @media (${BP.ipad}) {
    padding-top: ${Rem(60)};
  }
`;

export const StyledImageWithCardsBlocks = styled.div`
  @media (${BP.ipad}) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const StyledImageWithCardsBlock = styled.div`
  @media (${BP.ipad}) {
    width: 50%;
    max-width: ${Rem(610)};
  }

  &:first-child {
    margin-bottom: ${Rem(42)};

    @media (${BP.ipad}) {
      min-width: 50%;
      max-width: 50%;
      margin-bottom: 0;
      padding-right: ${Rem(80)};
    }
  }
`;

export const StyledImageWithCardsImage = styled.div`
  ${Ratio(375, 250)};
  width: 100%;
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
  
  @media(${BP.ipad}) {
    ${Ratio(608, 755)};
    max-height: ${Rem(755)};
  }
`;

export const StyledImageWithCardsItems = styled.div`
  padding-bottom: ${Rem(22)};

  @media (${BP.ipad}) {
    padding-bottom: 0;
  }
`;

export const StyledImageWithCardsItem = styled.div`
  position: relative;
  margin-bottom: ${Rem(42)};

  @media (${BP.ipad}) {
    padding-left: ${Rem(35)};
  }

  &:last-child {
    @media (${BP.ipad}) {
      margin-bottom: 0;
    }
  }
`;

export const StyledImageWithCardsItemIcon = styled.img`
  margin-bottom: ${Rem(14)};

  @media (${BP.ipad}) {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }
`;

export const StyledImageWithCardsItemTitle = styled.h2`
  font-size: ${Rem(30)};
  line-height: ${Rem(30)};
  color: ${Colors.yellow};
  margin-bottom: ${Rem(6)};
`;

export const StyledImageWithCardsItemSubtitle = styled.h3`
  font-size: ${Rem(20)};
  line-height: ${Rem(22)};
  margin-bottom: ${Rem(16)};
`;

export const StyledImageWithCardsItemParagraph = styled.p`
  font-size: ${Rem(14)};
  line-height: ${Rem(20)};
`;
